export const TranslationTable = {
    "English" : {
        "Menu_MainMenu" : "Index",
        "Menu_Education" : "Education",
        "Menu_Skills" : "Skills",
        "Menu_ProfessionalExperience" : "Professional Experience",
        "Menu_ProspereITB" : "Prospere ITB",
        "Menu_DRIM" : "DRIM",
        "Menu_Everial" : "Everial",
        "Menu_BresilEcoBuggy" : "Brésil Eco Buggy",
        "Menu_EFN1" : "EFN project PPMS",
        "Menu_EFN2" : "EFN Teacher",
        "prospere_itb_presentation" : "Prospere ITB is a Brazilian e-learning company.\n\nI helped Fernando, the company's developer \nto solve problems in the website's backlog\ndedicated to online courses. \n\nI used the C# language and the ASP.NET \nframework",
        "drim_presentation" : "Drim is a french company specializing in elevator \ncomponents and their embedded systems.\n\nMy job was to develop a 3D ordering system for \nceiling LEDs using THREE.js and make adjustments \nto the company's website using wordpress cms.",
        "everial_presentation" : "Everial is a document archiving company. \n\nIn the research and development department, i joined \nthe development team for the company's WPF \nsoftware, which is used internally. \n\nI used the C# programming language and my team and \ni used TFS for version control and the \nAgile development method.",
        "bresil_ecobuggy_presentation" : "Brésil eco-buggy is a Brazilian tourism company \nwhich organizes long-distance buggy expeditions along \nthe northeastern coast of Brazil.\n\nAs an expedition organizer, my main responsibilities \nwere to contact clients and make quotes for hotels \nand transportation. \n\nI also dealt with problems with the company's network \nand computers when needed.",
        "efn1_presentation" : "Escola francesa de Natal(French school of Natal)\n is a French school in Natal, Brazil.\n\nIn compliance with the requirements of the \nFrench Ministry of Education for the implementation \nof the PPMS system in French schools, I created an \nalarm system for the school.\n\nThe school asked me to develop a visual alarm system \nfor the classrooms, which I created using \nC++ (arduino framework) and electronics.",
        "efn2_presentation" : "For three months, I taught a programming class (Scratch) \nand Excel spreadsheets to the CM1 and CM2 classes \nat the French school in Natal.",
        "Floating_text_Python":"Python",
        "Floating_text_C#":"C#",
        "Floating_text_javaScript":"JavaScript",
        "Floating_text_React":"React",
        "Floating_text_Threejs":"Three.js",
        "Floating_text_blender":"Blender",
        "Floating_text_SQL":"SQL",
        "Floating_text_HTML_CSS":"HTML/CSS",
        "Floating_text_English":"English",
        "Floating_text_Portuguese":"Portuguese",
        "Floating_text_French":"French",
        "Fading_Title_1":"Everardo Meireles",
        "Fading_Title_2":"  Fullstack Developer",
        },

    "French" : {
        "Menu_MainMenu" : "Accueil",
        "Menu_Education" : "Formation",
        "Menu_Skills" : "Compétences",
        "Menu_ProfessionalExperience" : "Expérience Professionnelle",
        "Menu_ProspereITB" : "Prospere ITB",
        "Menu_DRIM" : "DRIM",
        "Menu_Everial" : "Everial",
        "Menu_BresilEcoBuggy" : "Brésil Eco Buggy",
        "Menu_EFN1" : "EFN projet PPMS",
        "Menu_EFN2" : "EFN professeur",
        "prospere_itb_presentation" : "Prospere ITB est une entreprise d'e-learning\nbrésilienne.\n\nJ'ai aidé Fernando, le développeur de l'entreprise\n a résoudre des problèmes dans le backlog de\n l'application web dédiée aux cours en ligne. \n\nJ'ai utilisé le langage C# et le framework \nASP.NET.",
        "drim_presentation" : "Drim est une entreprise spécialisée dans les composants\nd'ascenseurs et leurs systèmes embarqués.\n\n Mon travail consistait à développer un système\nde commande de plafonnier en 3D avec THREE.js et à faire\ndes ajustements dans le site de l'entreprise avec\nle cms wordpress.",
        "everial_presentation" : "Everial est une entreprise d'archivage\nde documents.\n\n Au service études et développement, j'ai intégré\nl'équipe de développement de l'application WPF\nde l'entreprise qui est utilisé en interne.\n\n J'ai utilisé le langage de programmation C#, avec\nmon équipe on utilisait tfs pour le contrôle de version\net la méthode Agile de développement.",
        "bresil_ecobuggy_presentation" : "Brésil eco-buggy est une entreprise de tourisme au Brésil qui fait\ndes expéditions en buggy de longues distances le long de la côte\ndu nord-est Brésilien.\n\n En tant qu'organisateur d'expéditions, mes principales\nresponsabilités étaient d'être en contact avec les clients et de\ndonner des devis d'hôtels et de transport.\n\nJe m'occupais aussi de problèmes avec le réseau de l'entreprise\net les ordinateurs.",
        "efn1_presentation" : "L'école française de Natal est une école française\nà Natal, au Brésil.\n\nEn conformité avec les exigences de l'Éducation nationale\n pour la mise en place du PPMS dans les écoles françaises,\nj'ai créé un système d'alarme pour l'école.\n\nL'école m'a demandé de développer un système d'alarme\nvisuel dans les salles de classe, que j'ai réalisé avec\nle C++(framework arduino) et l'électronique.",
        "efn2_presentation" : "Pendant trois mois, j'ai donné des cours de \nsensibilisation à la programmation (Scratch) et tableurs \nExcel à des classes de CM1 et CM2\n à l'école française de Natal.",
        "Floating_text_Python":"Python",
        "Floating_text_C#":"C#",
        "Floating_text_javaScript":"JavaScript",
        "Floating_text_React":"React",
        "Floating_text_Threejs":"Three.js",
        "Floating_text_blender":"Blender",
        "Floating_text_SQL":"SQL",
        "Floating_text_HTML_CSS":"HTML/CSS",
        "Floating_text_English":"Anglais",
        "Floating_text_Portuguese":"Portugais",
        "Floating_text_French":"Français",
        "Fading_Title_1":"Everardo Meireles",
        "Fading_Title_2":"Developpeur Fullstack",
    },

    "Portuguese" : {
        "Menu_MainMenu" : "Página inicial",
        "Menu_Education" : "Formação",
        "Menu_Skills" : "Competências",
        "Menu_ProfessionalExperience" : "Experiência profissional",
        "Menu_ProspereITB" : "Prospere ITB",
        "Menu_DRIM" : "DRIM",
        "Menu_Everial" : "Everial",
        "Menu_BresilEcoBuggy" : "Brésil Eco Buggy",
        "Menu_EFN1" : "EFN projet PPMS",
        "Menu_EFN2" : "EFN professor",
        "prospere_itb_presentation" : "A Prospere ITB é uma empresa de e-learning\nbrasileira.\n\nAjudei Fernando, o desenvolvedor da empresa\na resolver problemas no backlog do site\ndedicado a cursos online. \n\nusei a linguagem C# e o framework ASP.NET.",
        "drim_presentation" : "A Drim é uma empresa especializada em componentes\nde elevadores e seus sistemas embarcados.\n\nMeu trabalho foi desenvolver um sistema de encomenda\nde LEDS de teto 3D usando THREE.js e fazer ajustes\nno site da empresa usando o cms wordpress.",
        "everial_presentation" : "A Everial é uma empresa de arquivamento\nde documentos.\n\nNo departamento de pesquisa e desenvolvimento, \nentrei para a equipe de desenvolvimento do \nsoftware WPF da empresa, que é usado\ninternamente. \n\nUsei a linguagem de programação C# e minha \nequipe e eu usávamos o tfs para controle de versão \ne o método de desenvolvimento Agile.",
        "bresil_ecobuggy_presentation" : "A Brésil eco-buggy é uma empresa de turismo brasileira que\norganiza expedições de buggy de longa distância ao longo \nda costa nordestina do Brasil\n\nComo organizador de expedições, minhas principais \nresponsabilidades eram de entrar em contato com os clientes \ne fazer orçamentos de hotéis e transporte. \n\nEu também lidava com problemas na rede e nos computadores\nda empresa.",
        "efn1_presentation" : "A Escola Francesa de Natal é uma escola francesa em Natal\n no Brasil.\n\nEm conformidade com as exigências do Ministério da Educação \nda França para a implementação do sistema PPMS nas escolas \nfrancesas, criei um sistema de alarme para a escola.\n\nA escola me pediu para desenvolver um sistema de alarme visual\nnas salas de aula, que criei usando C++ (framework arduino)\ne eletrônica.",
        "efn2_presentation" : "Durante três meses, dei aulas de programação (Scratch) \ne planilhas de Excel para as turmas CM1 e CM2 da escola \nfrancesa em Natal.",
        "Floating_text_Python":"Python",
        "Floating_text_C#":"C#",
        "Floating_text_javaScript":"JavaScript",
        "Floating_text_React":"React",
        "Floating_text_Threejs":"Three.js",
        "Floating_text_blender":"Blender",
        "Floating_text_SQL":"SQL",
        "Floating_text_HTML_CSS":"HTML/CSS",
        "Floating_text_English":"Inglês",
        "Floating_text_Portuguese":"Português",
        "Floating_text_French":"Francês",
        "Fading_Title_1":"Everardo Meireles",
        "Fading_Title_2":"Desenvolvedor Fullstack",
    }
}